<template>
  <div v-if="isHaveAccess">
    <div v-if="isChinnostObchodnichZastupcuTabShow">
      <cinnost-obchodnich-zastupcu-tab />
    </div>
    <div v-else-if="isUkolovaniObchodnichZastupcuTabShow">
      <ukolovani-obchodnich-zastupcu-tab />
    </div>
    <div v-else-if="isObchodniRozpracovanostTabShow">
      <obchodni-rozpracovanost-tab />
    </div>
  </div>
</template>

<script>

import CinnostObchodnichZastupcuTab from "@/views/DohledNadOz/CinnostObchodnichZastupcuTab.vue";
import UkolovaniObchodnichZastupcuTab from "@/views/DohledNadOz/UkolovaniObchodnichZastupcuTab.vue";
import ObchodniRozpracovanostTab from "@/views/DohledNadOz/ObchodniRozpracovanostTab.vue";

export default {
  components: {CinnostObchodnichZastupcuTab, UkolovaniObchodnichZastupcuTab, ObchodniRozpracovanostTab},
  props: {
    tabName: {type: String, required: true},
  },
  computed: {
    isHaveAccess() {
      return this.$store.getters.isDohledNadOzPageAllowed;
    },
    isChinnostObchodnichZastupcuTabShow() {
      return this.tabName === 'cinnostObchodnichZastupcu';
    },
    isUkolovaniObchodnichZastupcuTabShow() {
      return this.tabName === 'ukolovaniObchodnichZastupcu';
    },
    isObchodniRozpracovanostTabShow() {
      return this.tabName === 'obchodniRozpracovanost';
    },
  },
};
</script>

