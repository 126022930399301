<template>
  <div class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="text-left button-line d-flex justify-content-between pl-1 pr-2">
        <dohled-tab-buttons tabName="cinnostObchodnichZastupcu" />
      </div>

      <div class="text-left filter-line d-flex">
        <span class="font-weight-bold pr-2 fs-17px pl-1">Filtry:</span>
        <span class="bold ml-3 pt-label">od</span>
        <b-form-datepicker
            id="dataFrom-datepicker"
            v-model="dateFrom"
            :start-weekday="1"
            size="sm"
            locale="cs"
            class="ml-2 w-260px"
        />

        <span class="bold ml-3 pt-label">do</span>
        <b-form-datepicker
            id="dataTo-datepicker"
            v-model="dateTo"
            :start-weekday="1"
            size="sm"
            locale="cs"
            class="ml-2 w-260px"
        />
      </div>

      <div class="analyseTable">
        <b-table
            sticky-header="calc(100vh - 14.5rem)"
            bordered
            hover
            no-border-collapse
            :items="users"
            :fields="headers"
            class="stickyTable"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-direction="desc"
        >
          <template #head()="{ field }">
            <span v-html="field.label" class="text-nowrap"></span>
          </template>
          <template #cell(userFullName)="{ item }">
            <div class="text-left" :class="[item.activeInSystem ? 'active-in-system-user' : '']">
              <b-icon-check-circle-fill
                  v-if="item.activeInSystem"
                  font-scale="1.65"
                  variant="success"
                  class="pr-1 pt-4px"
              />
              {{ item.userFullName }}
            </div>
          </template>
          <template #cell(plneniOprotiPlanu)="{ item }">
            {{ getPlneniOprotiPlanu(item) }}
          </template>
        </b-table>
      </div>

    </b-overlay>
  </div>
</template>


<script>

import moment from "moment";
import Axios from "axios";
import DohledTabButtons from "@/views/DohledNadOz/DohledTabButtons.vue";
import commonMixin from "@/mixins/common.mixin.ts";

const getDateFrom = (monthCount = 1) => moment.utc().subtract(monthCount, 'month').toDate();
const getDateTo = () => moment.utc().toDate();
const getStartDay = (date) => moment.utc(date).startOf('day').toDate();
const getEndDay = (date) => moment.utc(date).endOf('day').toDate();

export default {
  components: {DohledTabButtons},
  mixins: [commonMixin],
  data() {
    return {
      isLoading: false,
      dateFrom: getDateFrom(),
      dateTo: getDateTo(),
      headers: [
        { label: 'Jméno, Příjmení (příp. titul)', key: 'userFullName', variant: 'color-1', class: 'mw-250px', sortable: true },
        { label: 'Čislo OZ', key: 'numberOZ', variant: 'color-1', class: 'w-125px', sortable: true },
        { label: 'Nové obchodní<br>případy', key: 'noveObchodniPripadyCount', variant: 'color-1', class: 'mw-125px', sortable: true },
        { label: 'Počet výher', key: 'pocetVyher', variant: 'color-1', class: 'mw-115px', sortable: true },
        { label: 'Suma výher<br><span class="font-weight-normal">(ceny bez DPH)</span>', key: 'sumaVyher', variant: 'color-1', class: 'w-150px', sortable: true, formatter: cur => this.formatMoney(cur) },
        { label: 'Počet proher', key: 'pocetProher', variant: 'color-1', class: 'mw-115px', sortable: true },
        { label: 'Celkový přehled naplanovaných aktivit<br><span class="font-weight-normal">telefonát / email / schůzka / jiné</span>', key: 'naplanovanoAktivit', variant: 'color-1', class: 'mw-150px', sortable: true },
        { label: 'Celkový přehled splněných aktivit<br><span class="font-weight-normal">telefonát / email / schůzka / jiné</span>', key: 'splnenoAktivit', variant: 'color-1', class: 'w-150px', sortable: true },
        { label: 'Plnění oproti plánu<br><span class="font-weight-normal">40 schůzek / 1,8 mil. Kč</span>', key: 'plneniOprotiPlanu', variant: 'color-1', class: 'w-125px', sortable: true },
        { label: 'Počet přihlášení', key: 'numberLogins', variant: 'color-1', class: 'w-125px', sortable: true },
      ],
      sortBy: 'userFullName',
      sortDesc: false,
      users: [],
    };
  },
  computed: {
    isHaveAccess() {
      return this.$store.getters.isDohledNadOzPageAllowed;
    },
  },
  methods: {
    async getUsersInfo() {
      try {
        const url = `/dohled/getCinnostOzInfo`;
        const res = await Axios.post(url, {dateFrom: getStartDay(this.dateFrom), dateTo: getEndDay(this.dateTo)});
        const {data} = res;
        this.users = data.data.users;
      } catch (e) {
        console.log(e)
      }
    },
    getPlneniOprotiPlanu(item) {
      const periodDays = moment(this.dateTo).diff(this.dateFrom, 'days') + 1;
      const percentPlanuSummaVyher = this.getPercentPlanuSummaVyher(item, periodDays);
      const percentPlanuSchuzek = this.getPercentPlanuSchuzek(item, periodDays);
      return `${this.formatPercentZero(percentPlanuSchuzek, 1)} / ${this.formatPercentZero(percentPlanuSummaVyher, 1)}`
    },
    getPercentPlanuSummaVyher(item, periodDays) {
      const isSumaVyher = !!item.sumaVyher;
      if (!isSumaVyher) return 0;

      return item.sumaVyher / periodDays * 30 / 1800000 * 100;
    },
    getPercentPlanuSchuzek(item, periodDays) {
      const isDoneSchuzek = !!item.activities?.meetings;
      if (!isDoneSchuzek) return 0;

      return item.activities.meetings / periodDays * 30 / 40 * 100;
    },
  },
  watch: {
    dateFrom: {
      async handler() {
        await this.getUsersInfo();
      }
    },
    dateTo: {
      async handler() {
        await this.getUsersInfo();
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getUsersInfo();
    this.isLoading = false;
  },
};
</script>

<style>
 .lh-2 {
   line-height: 2;
 }
</style>
